import type { Jsonify } from "type-fest";

import type { CreateLeadRequest, Lead, UpdateLeadBookingInfoRequest } from "@wind/marketing/models";

import Api from "~/api/Api.js";

const ROOT_PATH = "/leads";

/**
 * Public leads API
 *
 * @author @mxs2019
 */
class PublicLeadsApi extends Api {
  static forLoaderRequest(request: Request) {
    return new PublicLeadsApi(Api.configForLoaderRequest(request));
  }

  static forActionRequest(request: Request) {
    return new PublicLeadsApi(Api.configForActionRequest(request));
  }

  getLead(leadId: string): Promise<Jsonify<{ lead: Lead }>> {
    return this.get({
      path: `${this.publicPath(ROOT_PATH)}/${leadId}`,
    });
  }

  createLead(lead: CreateLeadRequest): Promise<Jsonify<{ lead: Lead }>> {
    return this.post({
      path: this.publicPath(ROOT_PATH),
      data: lead,
    });
  }

  updateLeadBookingInfo(
    leadId: string,
    lead: UpdateLeadBookingInfoRequest
  ): Promise<Jsonify<{ lead: Lead }>> {
    return this.put({
      path: `${this.publicPath(ROOT_PATH)}/${leadId}/booking`,
      data: lead,
    });
  }
}

export default PublicLeadsApi;
